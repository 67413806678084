import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import immer from "immer";
import styled from "styled-components/macro";
import moment from "moment";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";

import { TelephoneMask } from "./TelephoneInput.element";
import { DatePicker, TextInput, Select, Flex } from "../Elements.js";
import { getToken } from "../../constants";
import { omit } from "lodash";

const GridContainer = styled.div`
  display: grid;
  gap: 8px;

  grid-template:
    "firstname lastname"
    "email company"
    "relocatingTo currency"
    "cluster phone"
    "servicestartdate paiduntil"
    "features ...";
    / 1fr 1fr;
`;

/** @type {any} */
let GridArea = styled.div`
  grid-area: ${p => p.name};
`;

const GET_COMPANIES = gql`
  query($token: String!) {
    companies(token: $token) {
      _id
      name
    }
  }
`;

const GET_CLUSTER_OPTIONS = gql`
  query($token: String!) {
    clusterNames(token: $token)
  }
`;


export const EuroDefaultCurrencyType = {
  name: "Euro",
  code: "EUR",
  symbol: "€",
};

export const GET_SUPPORTED_CURRENCIES = gql`
  query {
    supportedCurrencies {
      name
      code
      symbol
    }
  }
`;
/***
 * When adding or removing properties you
 * need to also update the locations array in
 * packages/graphql-backend/src/db/models/Client.js
 */
export const LOCATIONS = [
  "Amsterdam",
  "Berlin",
  "London",
  "Chicago",
  "Eindhoven",
  "Oslo",
  "San Diego",
  "Delft",
  "Utrecht",
  "Nijmegen",
  "Haarlem",
  "Ede",
  "The Hague",
  "Amersfoort",
  "Test Region",
  "Hamburg",
  "Rotterdam",
  "Departing",
  "Stockholm",
  "Groningen",
  "Sint Oedenrode",
  "Velsen-Noord",
  "Veghel",
  "Arnhem",
].sort();

const filter = createFilterOptions();
export const ClientForm = ({ client, onClientChange }) => {
  const {
    company,
    currency = null,
    email,
    firstName,
    lastName,
    paidUntil,
    phone,
    relocatingTo = null,
    cluster = "",
    serviceStartDate,
  } = client;

  const features = omit(client.features, "__typename");

  const { data } = useQuery(GET_COMPANIES, {
    variables: {
      token: getToken(),
    },
  });

  const { data: clusterData, updateQuery: updateClusterQuery } = useQuery(
    GET_CLUSTER_OPTIONS,
    {
      variables: {
        token: getToken(),
      },
    }
  );

  const { data: currencyData = {} } = useQuery(GET_SUPPORTED_CURRENCIES);
  const { supportedCurrencies = [] } = currencyData;

  const [isFirstWorkingDay, set_isFirstWorkingDay] = useState(false);

  useEffect(() => {
    //Open day: Enable the chat for the first working day of the month for the first 10 months after the service
    const get_first_working_day = (year, month) => {
      // get the first day of the specified month and year
      let first_working_day = moment()
        .year(year)
        .month(month)
        .date(1);

      // add days until the day is a working day (in case the first is saturday or sunday)
      while (first_working_day.day() % 6 === 0) {
        first_working_day = first_working_day.add(1, "day");
      }

      // return the day
      return first_working_day;
    };

    const firstWorkingDayOfMonth = get_first_working_day(
      new Date().getFullYear(),
      new Date().getMonth()
    );

    if (moment().isSame(firstWorkingDayOfMonth)) {
      if (!isFirstWorkingDay) set_isFirstWorkingDay(true);
    }
  }, []);

  const tenMonthsFromService = moment(new Date(paidUntil)).add(10, "months");

  const sortedLocations = LOCATIONS.sort((a, b) => a.localeCompare(b));

  return (
    <>
      {isFirstWorkingDay && moment().isBefore(tenMonthsFromService) && (
        <p style={{ color: "red", padding: "10px" }}>
          Today is the first working day of the month - the chat will be active
          (unless it is manually disabled)
        </p>
      )}

      <GridContainer>
        <GridArea name="firstname">
          <TextInput
            label="First Name*"
            onChange={e =>
              onClientChange({ ...client, firstName: e.target.value })
            }
            value={firstName}
          />
        </GridArea>
        <GridArea name="lastname">
          <TextInput
            label="Last Name*"
            onChange={e =>
              onClientChange({ ...client, lastName: e.target.value })
            }
            value={lastName}
          />
        </GridArea>
        <GridArea name="email">
          <TextInput
            label="Email*"
            onChange={e => onClientChange({ ...client, email: e.target.value })}
            value={email}
          />
        </GridArea>
        <GridArea name="phone">
          <TextInput
            label="Phone"
            onChange={e => {
              onClientChange({
                ...client,
                phone: e.target.value,
              });
            }}
            value={phone}
            InputProps={{ inputComponent: TelephoneMask }}
          />
        </GridArea>

        <GridArea name="company">
          <Select
            label="Company*"
            items={
              data?.companies?.map?.(company => ({
                title: company.name,
                value: company._id,
              })) ?? []
            }
            onChange={e =>
              onClientChange(
                immer(client => {
                  if (!e.target.value) {
                    client.company = null;
                  } else {
                    client.company = {
                      _id: e.target.value,
                      __typename: "Company",
                    };
                  }
                })
              )
            }
            value={company?._id ?? ""}
          />
        </GridArea>
        <GridArea name="relocatingTo">
          <Select
            label="Relocating to*"
            items={sortedLocations.map(location => ({
              title: location,
              value: location,
            }))}
            onChange={e =>
              onClientChange({ ...client, relocatingTo: e.target.value })
            }
            value={relocatingTo}
          />
        </GridArea>
        <GridArea name="cluster">
          <Autocomplete
            onChange={(event, newValue) => {
              if (!newValue) {
                onClientChange({ ...client, cluster: "" });
              } else if (newValue && newValue.inputValue) {
                // Create a new value from the user input
                onClientChange({ ...client, cluster: newValue.inputValue });
                updateClusterQuery(store => {
                  if (!store.clusterNames.includes(newValue.inputValue)) {
                    store.clusterNames.push(newValue.inputValue);
                  }
                });
              } else {
                onClientChange({ ...client, cluster: newValue.value });
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              // Suggest the creation of a new value
              if (params.inputValue !== "") {
                filtered.push({
                  inputValue: params.inputValue,
                  title: `Add new cluster name: "${params.inputValue}"`,
                });
              }

              return filtered;
            }}
            selectOnFocus
            handleHomeEndKeys
            value={cluster}
            id="ge-cluster"
            options={clusterData?.clusterNames.map(name => {
              return {
                title: name,
                value: name,
              };
            })}
            getOptionLabel={option => {
              // Value selected with enter, right from the input
              if (typeof option === "string") {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              // Regular option
              return option.title;
            }}
            renderOption={option => option.title}
            style={{ width: "100%" }}
            renderInput={params => (
              <TextInput {...params} label="GE cluster*" />
            )}
          />
        </GridArea>
        <GridArea name="servicestartdate">
          <DatePicker
            label="Service start date"
            value={serviceStartDate ? new Date(serviceStartDate) : null}
            onChange={d => {
              d.setHours(0, 0, 0);
              const dateStr = d.toISOString();
              return onClientChange({ ...client, serviceStartDate: dateStr });
            }}
          />
        </GridArea>
        <GridArea name="paiduntil">
          <DatePicker
            label="Service end date"
            value={paidUntil ? paidUntil : null}
            onChange={d => {
              d.setHours(23, 59, 59);
              const dateStr = d.toISOString();
              if (moment().isBefore(dateStr)) {
                return onClientChange({ ...client, paidUntil: dateStr });
              }
              const confirmationStr =
                "Date is in the past, this means the client will be unable to chat. \n\nAre you sure you want to continue?";
              if (window.confirm(confirmationStr)) {
                return onClientChange({ ...client, paidUntil: dateStr });
              }
            }}
          />
        </GridArea>
        <GridArea name="currency">
          <Select
            label="Currency*"
            items={supportedCurrencies.map(({ name, code }) => ({
              title: name,
              value: code,
            }))}
            onChange={e =>
              onClientChange({ ...client, currency: e.target.value })
            }
            value={currency}
          />
        </GridArea>
        <GridArea name="features">
          {Object.entries(features).length > 0 && (
            <GridArea name="paiduntil">
              <div style={{
                height: 32,
              }} />

              <Flex column>
                <h2>Feature access</h2>

                <FormControlLabel
                  key={"communitytab"}
                  control={
                    <Switch
                      checked={features.communitytab}
                      onChange={e =>
                        onClientChange(
                          immer(client => {
                            client.features.communitytab = e.target.checked;
                          }),
                        )
                      }
                      color="primary"
                    />
                  }
                  label={"Together (in mobile app)"}
                />

                <FormControlLabel
                  key={"manuallyDisableChat"}
                  control={
                    <Switch
                      checked={features.manuallyDisableChat}
                      onChange={e =>
                        onClientChange(
                          immer(client => {
                            client.features.manuallyDisableChat =
                              e.target.checked;
                          }),
                        )
                      }
                      color="primary"
                    />
                  }
                  label={"Manually disable chat (web & mobile)"}
                />
                <p style={{ color: "#7B7B7B", fontSize: "12px" }}>
                  If the "manually disable chat" is on, the chat is not active
                  anymore regardless of the service dates.
                </p>

                <FormControlLabel
                  key={"orderOverview"}
                  control={
                    <Switch
                      checked={features.orderOverview}
                      onChange={e =>
                        onClientChange(
                          immer(client => {
                            client.features.orderOverview = e.target.checked;
                          }),
                        )
                      }
                      color="primary"
                    />
                  }
                  label={"Order overview"}
                />
              </Flex>
            </GridArea>
          )}
        </GridArea>
      </GridContainer>
    </>
  );
};
