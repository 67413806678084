import React, { useEffect, useState } from "react";
import { isEqual } from "lodash";

import { prepare_for_mutation } from "../../Graphql.js";
import { Whitespace, Button, Card } from "../Elements.js";
import { ClientForm } from "./ClientForm";

const input_client = client => {
  return prepare_for_mutation({
    firstName: client.firstName,
    lastName: client.lastName,
    nickname: client.nickname,
    birthDate: client.birthDate,
    email: client.email,
    paidUntil: client.paidUntil,
    phone: client.phone,
    serviceStartDate: client.serviceStartDate,
    relocatingTo: client.relocatingTo,
    role: client.role,
    cluster: client.cluster,
    profileType: client.profileType,
    features: client.features,
    companyId: client.company?._id,
    currency: client.currency,
    initiationId: client.initiationId,
  });
};

export const ClientProfile = ({ client: propsClient, onClientChange }) => {
  const [client, setClient] = useState(propsClient);

  useEffect(() => {
    setClient(propsClient);
  }, [propsClient]);

  return (
    <Card.Content>
      <ClientForm client={client} onClientChange={setClient} />
      <Whitespace height={8} />
      <Button
        style={{ float: "right" }}
        disabled={isEqual(input_client(client), input_client(propsClient))}
        onClick={() => onClientChange(client)}
      >
        Update
      </Button>
    </Card.Content>
  );
};
