import React from "react";
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import { Whitespace, MaterialIcon, Card } from "../Elements.js";
import { RouteTabs } from "../PageElements.js";
import { ClientProfile } from "./ClientProfile.js";
import { ClientImportRoadmap } from "./ClientImportRoadmap";
import { TodoView } from "../TodoView";
import { ClientTasks } from "./ClientTasks";
import { ROADMAP_TYPE } from "../Company/CompanyRoadmap";

export let ClientLayout = ({
  todoSuggestions,
  companyTodos,
  onBatchesChange,
  client,
  onClientChange,
}) => {
  let match = useRouteMatch();

  return (
    <Card>
      <Card.Title>
        {client.firstName} {client.lastName}{" "}
        {client.nickname ? `(${client.nickname})` : null}
        <span style={{
          color: client?.features?.chat ? "green" : "red",
        }}>- chat {client?.features?.chat ? "enabled" : "disabled"}</span>
      </Card.Title>
      <RouteTabs
        tabs={[
          {
            label: "Profile",
            to: "profile",
            icon: <MaterialIcon name="face" size={18} />,
          },
          {
            label: "Roadmap",
            to: "roadmap",
            icon: <MaterialIcon name="date_range" size={18} />,
          },
          {
            label: "Tasks",
            to: "tasks",
            icon: <MaterialIcon name="assignment" size={18} />,
          },
        ]}
      />

      <Switch>
        <Route
          path={`${match.path}/profile`}
          render={() => (
            <ClientProfile client={client} onClientChange={onClientChange} />
          )}
        />
        <Route
          path={`${match.path}/roadmap`}
          render={() => (
            <Card.Content>
              <ClientImportRoadmap
                company={client.company}
                onBatchesChange={onBatchesChange}
                clientBatches={client.batches}
              />
              <Whitespace height={12} />
              <TodoView
                key={client._id}
                roadmapType={ROADMAP_TYPE.PERSONAL}
                batches={client.batches}
                allowances={client.allowances}
                onBatchesChange={onBatchesChange}
                todoSuggestions={todoSuggestions}
                companyTodos={companyTodos}
              />
            </Card.Content>
          )}
        />
        <Route
          path={`${match.path}/tasks`}
          render={() => (
            <ClientTasks client={client} onClientChange={onClientChange} />
          )}
        />
        <Redirect exact from={`${match.path}/`} to={`${match.url}/profile`} />
      </Switch>
      <Whitespace height={100} />
    </Card>
  );
};
